:root {
  --override-primary-bg-color: #a6d0dd;
  --override-primary-text-color: black;
  --override-secondary-bg-color: #ff6969;
  --override-secondary-text-color: white;
  --override-info-bg-color: #ffd3b0;
  --override-info-text-color: black;
  --override-default-bg-color: #fff9de;
  --override-default-text-color: black;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}
